<template>
    <v-container>
        <div class="d-flex">
            <v-btn
                icon
                color="accent darken-3"
                class="align-self-center"
                link
                :to="prevRoutePath"
            >
                <v-icon left> fa-solid fa-chevron-left </v-icon>
            </v-btn>
            <h2 class="primary--text main-heading">agROBOfood Privacy Terms</h2>
        </div>
        <v-divider class="mx-4" inset vertical> </v-divider>
        <v-spacer></v-spacer>
        <base-card>
            <template #content>
                <v-card-text>
                    <div class="py-5">
                        <h2 class="text-h5 font-weight-bold">
                            Personal Data Protection Policy
                        </h2>
                        <p>
                            Whenever you use the agROBOfood website, you are
                            bound by the applicable Personal Data Protection
                            Policy; therefore, you should carefully read this
                            text whenever you use the platform and make sure you
                            accept it. At agROBOfood we pay special attention to
                            security and respect the privacy and confidentiality
                            of the users' personal data. That is why we have
                            invested time and resources to fully comply with the
                            applicable national, European and international
                            legal framework, and the European Union's General
                            Data Protection Regulation 679/2016. If you have any
                            questions or concerns about how your personal
                            information is handled, you may contact us at
                            info@agrobofood.eu. This Privacy Policy, together
                            with the Terms and conditions posted on our website,
                            set forth the general rules and policies governing
                            your use of our website. Depending on your
                            activities when visiting our website, you may be
                            required to agree to additional terms and
                            conditions.
                        </p>

                        <h2 class="text-h5 font-weight-bold">
                            Collection of Data through our website
                        </h2>
                        <p>
                            You can use our website without disclosing your
                            personal data. You are not required to provide
                            personal information as a condition of using our
                            site, except as may be necessary to provide you a
                            service at your request. When you use our website,
                            data may be stored for various security purposes.
                            This data may include the name of your internet
                            service provider, the web site that you used to link
                            to our site, the web sites that you visit from our
                            site and your IP-Address. This data could possibly
                            lead to your identification, but we do not use it to
                            do so. We do use the data from time to time for
                            statistical purposes but maintain the anonymity of
                            each individual user so that the person cannot be
                            identified. In cases when personal data is provided
                            to others to provide you products or services you
                            have requested, or for other purposes, we rely on
                            technical and organisational means to assure that
                            applicable data security regulations are followed.
                        </p>

                        <h2 class="text-h5 font-weight-bold">
                            Collection and Processing of Personal Data
                        </h2>
                        <p>
                            The personal data you enter anywhere on the
                            agROBOfood website are subject to processing and are
                            stored in a file under the responsibility of DRAXIS,
                            only for reasons relating to: a) the communication
                            and dissemination of the agROBOfood, b) processing
                            of your requests, c) the provision, with your
                            consent, of information regarding the agROBOfood.
                            Your personal data are not allowed to be used by any
                            third party. except as provided by the law and this
                            Policy. agROBOfood website does not collect or
                            process sensitive personal data, namely, data
                            related to a user's health, sex life, sexual
                            orientation, genetic or biometric data, or data
                            revealing one's racial or ethnic origin, political
                            opinions, religious or philosophical beliefs or
                            membership in a trade union. When you sign up on the
                            agROBOfood newsletter we collect the information you
                            provide us, including your e-mail. The personal data
                            (simple and not sensitive) we collect are
                            automatically anonymized or at least pseudonymised
                            and encrypted. The personal information we collect
                            will be only used for the defined, explicit, and
                            legitimate purposes explained to you and will not be
                            further processed in a manner incompatible with
                            those purposes. Moreover, we limit the collection to
                            only that information that is appropriate, relevant
                            and necessary for the purposes explained to you. If
                            not required by law, we will not obtain your consent
                            before collecting your personal data from third
                            parties. Instead, it will be deemed that you have
                            previously given such consent to any third party
                            from whom the agROBOfood site receives such
                            information.
                        </p>

                        <h2 class="text-h5 font-weight-bold">
                            Security of the personal information
                        </h2>
                        <p>
                            We do not share your personal data with others
                            except as described in this Personal Data Protection
                            Policy and the Terms of Use. The agROBOfood website
                            makes reasonable efforts to maintain your personal
                            data, in accordance with the terms of this Personal
                            Data Protection Policy and the Terms of Use. The
                            agROBOfood website addresses the issue of protecting
                            your anonymity and personal information very
                            seriously. We protect your personal data and, in
                            general, the information we receive about you, and
                            we guarantee their confidentiality, integrity and
                            availability using appropriate security measures,
                            according to the most up-to-date and advanced
                            technological methods. These measures include
                            technical and procedural steps to protect your data
                            from misuse, unauthorized access or disclosure,
                            loss, alteration or destruction.
                        </p>

                        <h2 class="text-h5 font-weight-bold">Use of Cookies</h2>
                        <p>
                            The agROBOfood website uses “cookies” to personalize
                            your online experience. “A cookie is a small piece
                            of data sent from a website and stored on your
                            computer by the user’s web browser. When you request
                            another page from the server, your browser sends the
                            cookie back to the server.” One of the primary
                            purposes of cookies is to provide a convenience
                            feature to save you time. You have the ability to
                            accept or decline cookies. Most web browsers
                            automatically accept cookies, but you can usually
                            modify your browser settings to decline cookies if
                            you prefer. If you choose to decline cookies, you
                            might not be able to fully experience the
                            interactive features of the agROBOfood website.
                        </p>

                        <h2 class="text-h5 font-weight-bold">
                            Privacy Policy Changes
                        </h2>
                        <p>
                            Although most changes are likely to be minor,
                            agROBOfood may change its Privacy Policy from time
                            to time, and in agROBOfood sole discretion. We
                            encourage you to frequently check this page for any
                            changes to its Privacy Policy. Your continued use of
                            this website after any change in this Privacy Policy
                            will constitute your acceptance of such change.
                        </p>

                        <h2 class="text-h5 font-weight-bold">
                            Credit & Contact Information
                        </h2>
                        <p>
                            This privacy policy was created by agROBOfood If you
                            have any questions about this Privacy Policy, please
                            contact us via: info@agrobofood.eu
                        </p>

                        <h2 class="text-h5 font-weight-bold">
                            Terms & Conditions
                        </h2>
                        <p>
                            Access to and use of this website are subject to the
                            following conditions. Please do not use this website
                            unless you agree with these conditions. This website
                            has been developed by agROBOfood Project, and is
                            administrated by the same. We reserve the right to
                            discontinue or to make partial or complete
                            modifications to this website or to the Conditions
                            of Use. Please note that we may make such changes at
                            our own discretion and without prior announcement.
                            We must therefore ask you, next time you visit this
                            website, to view the conditions again and to note
                            any changes or amendments that may have been made.
                        </p>

                        <h2 class="text-h5 font-weight-bold">
                            Surrender of use and benefit
                        </h2>
                        <p>
                            All details, documents and illustrations published
                            on this website are the sole property of agROBOfood
                            and they are protected by intellectual property laws
                            and industrial. They may not carry out acts of
                            reproduction, modification, distribution or public
                            communication of the website or any of its elements
                            without the prior written consent of agROBOfood Any
                            permission to use the same is granted on the
                            provison that the relevant copyright note is
                            displayed on all copies, that such details are only
                            used for personal purposes, that they are not
                            exploited commercially, that the details are not
                            modified in any way and that all illustrations
                            gained from the website are only used in conjunction
                            with the accompanying text. Users of the website may
                            only make private and personal use of the contents
                            of it. It is absolutely forbidden to use the website
                            or any of its elements for commercial or illegal
                            purposes. agROBOFood will not assume any liability
                            arising from the use by others of the content of the
                            website and may exercise all civil or criminal
                            actions that apply to infringements of these rights
                            by the user.
                        </p>

                        <h2 class="text-h5 font-weight-bold">Cookies</h2>
                        <p>
                            We employ the use of cookies. By using agROBOfood
                            website, you consent to the use of cookies in
                            accordance with agROBOfood Privacy Policy. Most of
                            the modern-day interactive websites use cookies to
                            enable us to retrieve user details for each visit.
                            Cookies are used in some areas of our site to enable
                            the functionality of this area and ease of use for
                            those people visiting.
                        </p>

                        <h2 class="text-h5 font-weight-bold">Modifications</h2>
                        <p>
                            In order to improve the performance of the website,
                            agROBOfood reserves the right at any time and
                            without notice to the user, to modify, extend or
                            suspend the presentation, configuration, technical
                            specifications and website services, unilaterally.
                            It also reserves the right to modify at any time
                            these conditions of use and any other particular
                            conditions.
                        </p>

                        <h2 class="text-h5 font-weight-bold">
                            Limited liability
                        </h2>
                        <p>
                            agROBOfood has compiled the detailed information
                            provided on this website from internal and external
                            sources to the best of its knowledge and belief,
                            using professional diligence. We endeavour to expand
                            and update this range of information on an ongoing
                            basis. The information on this website is purely for
                            the purpose of presenting agROBOfood and its
                            services. However, no representation is made or
                            warranty given, either expressly or tacitly, for the
                            completeness or correctness of the information on
                            this website. Please be aware that this information
                            although accurate on the day it was published may no
                            longer be up to date. We therefore recommend that
                            you check any information you obtain from this
                            website prior to using it in whatever form. Users of
                            this website declare that they agree to access the
                            website and its content at their own risk. To the
                            extent permitted by law, neither agROBOfood nor
                            third parties involved in the writing, production or
                            transmission of this website can be held liable for
                            damage or injury resulting from access or the
                            impossibility of access or from the use or
                            impossibility of use of this website or from the
                            fact that you have relied on information given on
                            this website. This does not affect our liability for
                            death or personal injury arising from our
                            negligence, nor our liability for fraudulent
                            misrepresentation or misrepresentation as to a
                            fundamental matter, nor any other liability which
                            cannot be excluded or limited under applicable law.
                        </p>

                        <h2 class="text-h5 font-weight-bold">
                            Websites of third-party vendors/links
                        </h2>
                        <p>
                            This website contains links/references to
                            third-party websites. By providing such links,
                            agROBOfood does not give its approval to their
                            contents. Neither does agROBOfood accept any
                            responsibility for the availability or the contents
                            of such websites or any liability for damage or
                            injury resulting from the use of such contents, of
                            whatever form. agROBOfood offers no guarantee that
                            pages linked to provide information of consistent
                            quality. Links to other websites are provided to
                            website users merely for the sake of convenience.
                            Users access such websites at their own risk. The
                            choice of links should in no way restrict users to
                            the linked pages.
                        </p>

                        <h2 class="text-h5 font-weight-bold">
                            Details supplied by yourself
                        </h2>
                        <p>
                            The user of this website is fully responsible for
                            the content and correctness of details he or she
                            sends to agROBOfood as well as for the non-violation
                            of any third-party rights that may be involved in
                            such details. The user gives his or her consent for
                            DRAXIS to store such details and to use the same for
                            the purpose of statistical analysis or for any other
                            specified business purpose, unless the information
                            involves personal details, going beyond master data
                            or usage data
                        </p>

                        <h2 class="text-h5 font-weight-bold">
                            Personal data protection
                        </h2>

                        <p>
                            By the mere fact of visiting the Web site is not
                            automatically register any personal data identifying
                            the user. However, agROBOfood informs users, in
                            accordance with “REGULATION (EU) 2016/679 OF THE
                            EUROPEAN PARLIAMENT And OF THE COUNCIL on April 27
                            of 2016 relating to the protection of the physical
                            persons regarding personal data processing and the
                            free circulation of these data, with entrance in
                            force the day May 25 of 2018, hereinafter, GDPR”,
                            that with access to certain services agROBOfood may
                            collect personal data that will be included in a
                            file of its property for the purposes determined in
                            each case. Similarly, agROBOfood informs the user of
                            the mandatory or optional, as appropriate, of the
                            data requested and if the consequences of providing
                            or refusing to provide. With the completion of data,
                            the user expressly consents in which they are
                            treated by agROBOfood may exercise their rights of
                            access, rectification, cancellation and opposition
                            under the terms established in the GDPR and
                            implementing regulations, by email sent to
                            info@agrobofood.eu. The User guarantees the accuracy
                            of the data provided and undertake to keep them
                            updated. agROBOfood guarantees that it has taken
                            appropriate security measures in accordance with the
                            provisions GDPR.
                        </p>

                        <h2 class="text-h5 font-weight-bold">
                            Credit & Contact Information
                        </h2>
                        <p>
                            This privacy policy was created by agROBOfood If you
                            have any questions about this Privacy Policy, please
                            contact us via:
                            <a
                             href="mailto:@agrobofood.eu"
                             class="primary--text"
                            >
                             info@agrobofood.eu
                            </a
                            >
                        </p>
                    </div>
                </v-card-text>
            </template>
        </base-card>
    </v-container>
</template>
<script>
export default {
    name: "PrivacyTerms",

    data() {
        return {
            prevRoute: null,
        };
    },
    computed: {
        prevRoutePath() {
            return this.prevRoute ? this.prevRoute.path : "/";
        },
    },

    beforeRouteEnter(_, from, next) {
        next((vm) => {
            vm.prevRoute = from;
        });
    },
};
</script>

<style scoped>
h2{
    margin: 10px 0;
}
</style>
